import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hoverable-tabs"
export default class extends Controller {
  static targets = ["tab"]

  connect() {
    console.log(this.tabTargets);

    this.tabTargets.forEach((tab) => {
      tab.addEventListener("mouseenter", this.switchTab.bind(this));
    });
  }

  disconnect() {
    super.disconnect();
  }


  switchTab(event) {
    let targetTabId = event.currentTarget.getAttribute("data-target");
    let targetTab = document.querySelector(targetTabId);

    document.querySelectorAll('.tab-pane').forEach(function(tabPane) {
      tabPane.classList.remove("show", "active");
    });

    targetTab.classList.add("show", "active");
  }

}

// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import * as bootstrap from "bootstrap"
import "./controllers"


// MAKE BOOTSTRAP GLOBAL
window.bootstrap = bootstrap


document.addEventListener("turbo:load", function() {
  var frame = document.getElementById('proposals');

  if (frame) {
    frame.addEventListener("click", function(e) {
      frame.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    });
  }
});


//
//
// MOBILE MENUE
import Mmenu from "mmenu-js"

let menu

document.addEventListener("turbo:load", () => {
  if (menu) {
    menu.node = {}
    menu.node.menu = document.querySelector("#mobilemenu")
    menu._initMenu()
    menu._initPanels()
    menu._initOpened()
    menu.setPage()
  } else {

    menu = new Mmenu( "#mobilemenu", {
      "setSelected": {
        "parent": true
      },
      "navbar": {
        title: "Menü",
        titleLink:"none"
      },
      "offCanvas": {
        "position": "right"
      },
      "navbars": [
        {
          "position": "top",
          "content": [
            "prev",
            "title",
            "close"
          ]
        }
      ]
    }, {
      offCanvas: {
        page: {
          selector: ".mobile-helper"
        }
      }
    })
  }
})
